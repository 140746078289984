import { Button } from "@medm-ui/button";
import { DialogActionsBar } from "@medm-ui/dialog";
import { UnionDialog } from "components/shared/dialog/dialog";
import { UnionConfig } from "models/union-config";
import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useAppSelector } from "redux/hooks";
import "./timeout.css";
import { logout } from "services/logout/logout.service";
import { SessionStorageService } from "services/storage-service";

export function Timeout(props: any) {
  const unionConfig = useAppSelector<UnionConfig>((state) => state.unionConfig.unionConfig);
  const [counter, setCounter] = useState(30);
  const [isIdle, setIsIdle] = useState(false);

  useEffect(() => {
    if (isIdle && counter > 0) {
      const timeDifference = new Date(SessionStorageService.getItem('autoLogoutTime', 'autoLogoutTime')).getTime() - new Date().getTime();
      const timeoutId = setInterval(() => {
        setCounter(Math.floor(timeDifference / 1000));
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [isIdle, counter]);

  useEffect(() => {
    if (new Date() > new Date(SessionStorageService.getItem('autoLogoutTime', 'autoLogoutTime')) || counter <= 0) {
      logout(unionConfig, true);
    }
  });

  useEffect(() => {
    // setting autoLogoutTime values
    const timeoutMinutes = unionConfig.userIdleTimeoutInSeconds.idle + unionConfig.userIdleTimeoutInSeconds.timeout;
    SessionStorageService.setItem(
      "autoLogoutTime",
      JSON.stringify(new Date(new Date().valueOf() + timeoutMinutes * 1000)),
      "autoLogoutTime"
    );
    const handleUserActive = () => {
      handleOnAction();
    };
    window.addEventListener('isUserActive', handleUserActive);
    return () => {
      window.removeEventListener('isUserActive', handleUserActive); // Cleanup event listener on unmount
    };
  }, []);

  const handleOnIdle = () => {
    if (unionConfig.userIdleTimeoutInSeconds.timeout) {
      const sessionExpiration = Math.ceil(
        (new Date(SessionStorageService.getItem("autoLogoutTime", "autoLogoutTime")).getTime() - new Date().getTime()) /
        1000
      );
      if (sessionExpiration < unionConfig.userIdleTimeoutInSeconds.timeout) {
        sessionExpiration > 0 ? setCounter(sessionExpiration) : setCounter(0);
      } else {
        setCounter(unionConfig.userIdleTimeoutInSeconds.timeout);
      }
      if (!isIdle) {
        setIsIdle(true);
      }
    }
  };

  const handleOnAction = () => {
    if (!isIdle) {
      setAutoTimeoutValue();
      reset();
    }
  };

  const setAutoTimeoutValue = () => {
    // setting autoLogoutTime values
    const timeoutMinutes = unionConfig.userIdleTimeoutInSeconds.idle + unionConfig.userIdleTimeoutInSeconds.timeout;
    SessionStorageService.setItem(
      "autoLogoutTime",
      JSON.stringify(new Date(new Date().valueOf() + timeoutMinutes * 1000)),
      "autoLogoutTime"
    );
  }

  const {
    reset,
  } = useIdleTimer({
    timeout: unionConfig.userIdleTimeoutInSeconds.idle * 1000,
    onAction: handleOnAction,
    onIdle: handleOnIdle,
    throttle: 500
  });

  const handleContinueSession = () => {
    setAutoTimeoutValue();
    setIsIdle(false);
    reset();
  };

  const children = (
    <>
      <div className="timeout-modal-container">
        <p className="container-text">
          Your session is about to be timed out due to inactivity. Select to either continue with the session or to
          logout.
        </p>
        <p className="container-text last-paragraph" data-test-id="timeout-last-paragraph">
          The system will automatically log you out in: <br /> <span className="countdown-text">{secsToMinutesAndSeconds(counter <= 0 ? 0 : counter)}</span>{" "}
          minutes.
        </p>
      </div>
      <DialogActionsBar layout="end">
        <Button
          buttonLabel="Continue session"
          buttonSize="medium"
          buttonType="Primary"
          buttonId="continue-session"
          onButtonClick={() => {
            handleContinueSession();
          }}
        />
        <Button
          buttonLabel="Log out"
          buttonSize="medium"
          buttonType="Subtle"
          buttonId="logout"
          onButtonClick={() => {
            logout(unionConfig);
          }}
        />
      </DialogActionsBar>
    </>
  );
  return (
    <>
      {unionConfig.userIdleTimeoutInSeconds?.idle > 0 && isIdle && (<UnionDialog
        id={props.id}
        title="Session Timeout"
        children={children}
        closeIcon={false}
        width={"480px"}
        className="timeout-dialog"
      ></UnionDialog>
      )}
    </>
  );
}

function secsToMinutesAndSeconds(duration: number): string {
  const minutes = Math.floor(duration / 60);
  const seconds = (duration % 60).toFixed(0);
  if (Number(seconds) === 60) {
    return (minutes + 1 + ":00");
  }
  else {
    let temp;
    if (Number(seconds) < 10) {
      temp = "0";
    }
    else temp = "";
    return (minutes + ":" + temp) + seconds;
  }
}
