import { DefaultLoaderSkeleton } from "./defaultLoaderSkeleton";
import React from "react";

const Sandbox = React.lazy(() => import('components/sandbox/sandbox'));

export const SandboxSkeleton = () => {
    return (
        <DefaultLoaderSkeleton>
            <Sandbox />
        </DefaultLoaderSkeleton>
    )
}