import axios from "axios";

const defaultRequestTimeout = 10000;
const defaultConnectionTimeout = 10000;

export function httpGet<T>(url: string, requestTimeout = defaultRequestTimeout, connectionTimeout = defaultConnectionTimeout): Promise<T> {
    const source = axios.CancelToken.source();
    return new Promise<T>((resolve, reject) => {
        let isResolved = false;
        axios
            .get(url, { timeout: requestTimeout, cancelToken: source.token })
            .then((response) => {
                isResolved = true;
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })

        setTimeout(() => {
            if (!isResolved) {
                source.cancel();
            }
        }, connectionTimeout);
    });
}