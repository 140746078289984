import { PermissionedResource, PermissionSet } from "models";

export function getPermission(resource: PermissionedResource, permissionSet: PermissionSet): boolean {
  if (resource && resource.permissions && resource.permissions.length) {
    if (!permissionSet || !permissionSet[resource.productKey]) {
      return false;
    }

    return helper(resource, permissionSet);
  } else {
    if (permissionSet && permissionSet[resource?.productKey] === null) {
      return false;
    }
    return true;
  }
}

function helper(resource: PermissionedResource, permissionSet: PermissionSet): boolean {
  let hasAnyPermission = false;
  let hasMissingPermission = false;

  resource.permissions.forEach((routePermission: any) => {
    const routePermissionName = routePermission["name"] || routePermission;
    const routePermissionAccessLevel = routePermission["accessLevel"] || 0;

    const userPermission: any = permissionSet[resource.productKey].find(
      (x: any) => x === routePermissionName || x["name"] === routePermissionName
    );

    if (userPermission) {
      const userPermissionAccessLevel = userPermission["accessLevel"] || 0;
      if (userPermissionAccessLevel >= routePermissionAccessLevel) {
        hasAnyPermission = true;
      } else {
        hasMissingPermission = true;
      }
    } else {
      hasMissingPermission = true;
    }
  });

  if (resource.permissionRequirement === "All") {
    return !hasMissingPermission;
  } else {
    return hasAnyPermission;
  }
}
