import { UnionLoader } from "components/shared/loader";
import { useEffect, useState } from "react";
import { logout } from "services/logout/logout.service";
import { useAppSelector } from "../../redux/hooks";

export function Logout() {
  const unionConfig = useAppSelector((state) => state.unionConfig.unionConfig);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    logout(unionConfig).catch((error) => {
      console.error(error);
      setIsLoading(false);
    });
  }, [unionConfig]);

  return isLoading ? <UnionLoader /> : null;
}
