import { createSlice } from "@reduxjs/toolkit";
import { TenantItem } from "components/layout/header/tenant-switcher/tenant-switcher.types";

export interface TenantState {
    items: TenantItem[];
}

const defaultState: TenantState = {
    items: []
};

export const tenantSlice = createSlice({
    name: "tenant",
    initialState: defaultState,
    reducers: {
        setTenants: (state, action) => {
            return {
                ...state,
                items: action.payload,
            };
        }
    },
});

// Action creators are generated for each case reducer function
export const { setTenants } = tenantSlice.actions;

export default tenantSlice.reducer;