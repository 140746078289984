import CryptoES from "crypto-es";

export class LocalStorageService {
  public static getItem<T>(name: string, key: string): T {
    const item = localStorage.getItem(name);
    if (item) {
      return LocalStorageService.decryptData(item, name, key) as T;
    }
    return JSON.parse("{}") as T;
  }

  public static setItem(name: string, object: string, key: string): void {
    const encrypted = CryptoES.AES.encrypt(object, key);

    localStorage.setItem(name, encrypted.toString());
  }

  private static decryptData = (object: string, name: string, key: string): any => {
    let jsonObj = "";
    try {
      jsonObj = CryptoES.AES.decrypt(object, key).toString(CryptoES.enc.Utf8);
    } catch (ex) {
      LocalStorageService.setItem(name, object.toString(), key);
      const encryptedItem = localStorage.getItem(name);
      jsonObj = CryptoES.AES.decrypt(encryptedItem, key).toString(CryptoES.enc.Utf8);
    }
    if (!jsonObj) {
      jsonObj = "{}";
    }
    return JSON.parse(jsonObj);
  };
}
