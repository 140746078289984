import { createSlice } from "@reduxjs/toolkit";

export enum SideNavClass {
    Disabled = "side-nav-disabled"
}

export interface SideNavState {
    classes: SideNavClass[];
    isSideNavPinned: boolean;
}

const defaultState: SideNavState = {
    classes: [],
    isSideNavPinned: false
};

export const sideNavSlice = createSlice({
    name: "sideNav",
    initialState: defaultState,
    reducers: {
        setClasses: (state, action) => {
            return {
                ...state,
                classes: action.payload
            }
        },
        setIsSideNavPinned: (state, action) => {
            state.isSideNavPinned = action.payload;
        }
    },
});

export const { setClasses, setIsSideNavPinned } = sideNavSlice.actions;

export default sideNavSlice.reducer;