import { DefaultLoaderSkeleton } from "./defaultLoaderSkeleton";
import React from "react";
import { EnvironmentProps } from "components/environment/environment";

const Environment = React.lazy(() => import('components/environment/environment'));

export const EnvironmentSkeleton = (props: EnvironmentProps) => {
    return (
        <DefaultLoaderSkeleton>
            <Environment {...props} />
        </DefaultLoaderSkeleton>
    )
}