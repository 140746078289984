import { Footer } from "@medm-ui/footer";

export interface UnionFooterProps {
}

export function UnionFooter(props: UnionFooterProps) {
  const NavLink = [
    {
      displayName: "Modern Slavery Act Statement",
      url: "https://ihsmarkit.com/Legal/modern-slavery-statement.html",
    },
    {
      displayName: "Privacy Policy",
      url: "https://ihsmarkit.com/Legal/privacy.html",
    },
    {
      displayName: "Terms of Use",
      url: "https://www.spglobal.com/en/terms-of-use",
    },
    {
      displayName: "Disclaimers",
      url: "https://www.spglobal.com/en/terms-of-use",
    },
  ];
  return (
    <Footer
      id="footer"
      copyrightText="©$YEAR S&P Global. All rights reserved."
      linksList={NavLink}
    />
  );
}
