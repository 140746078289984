export interface IProductKeys {
    ilevel: string;
    compliance: string;
    subscriptions: string;
    companyportal: string;
    wsoweb :string
    powerbi: string
}

export const ProductKeys: IProductKeys = {
    ilevel: "ilevel",
    compliance: "compliance",
    subscriptions: "subscriptions",
    companyportal: "company-portal",
    wsoweb:"wsoweb",
    powerbi: "powerbi"
}