import { MenuItem, Sidenav } from "@medm-ui/sidenav";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { lastVisitedPage } from "services/last-visited-page/last-visited-page-helper";
import { getPermission } from "services/permission/permission-helper";
import "./sideNav.css";
import { MenuItem as MenuItemModel, PermissionSet, PermissionedResource } from "models";
import { SideNavProps } from "./sideNav.types";
import { SideNavType } from "./sideNavType";

export function SideNav({ pin, type, width }: SideNavProps) {
  const menu = useAppSelector((state) => state.menu.menu);
  const permission = useAppSelector((state) => state.permission.permission);
  const permissionsLoaded = useAppSelector((state) => state.permission.loaded);
  const unionConfig = useAppSelector((state) => state.unionConfig.unionConfig);
  const displayOnHomeItems = useAppSelector((state) => state.unionConfig.unionConfig.manifest.filter(item => item.displayOnHome === true).map(product => product.key));
  const product = useAppSelector((state) => state.unionConfig.activeProduct);
  const routeMap = useAppSelector((state) => state.route.routeMap);
  const sideNavState = useAppSelector((state) => state.sideNav);
  const isSideNavPinned = useAppSelector((state) => state.sideNav.isSideNavPinned);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const displayHomeItem = useMemo(() => type === SideNavType.Home && displayOnHomeItems && displayOnHomeItems.length > 0, [displayOnHomeItems]);

  const fetchChildItems = (item: MenuItemModel[]) => {
    let childData: any = [];
    let isActive = false;
    item.forEach((menuItem: any) => {
      if (menuItem.path === pathname) {
        isActive = true;
      }
      const d: any = {
        icon: menuItem.iconClass,
        label: menuItem.label,
        path: menuItem.path,
        current: menuItem.path === pathname,
      };
      if (menuItem.items && menuItem.items.length > 0) {
        const { childItem, current } = fetchChildItems(menuItem.items);
        if (childItem.length) {
          d.childNavItems = childItem;
          d.current = current;
        }
      }
      childData = filterMenuItemByPermissions(menuItem, permission, d, childData);
    });
    return { childItem: childData, current: isActive };
  }

  const sideNav = useMemo(() => {
    if (permissionsLoaded === false) {
      return [];
    }

    let sideData: MenuItem[] = [];
    if (menu) {
      let menuModel = menu;
      if (type === SideNavType.Home) {
        menuModel = menuModel.filter(m => displayOnHomeItems.includes(m.productKey));
      }
      else if (product && product.key) {
        menuModel = menuModel.filter(m => m.productKey === product.key);
      }
      if (displayHomeItem) {
        const homePath = "/";
        sideData.push({
          icon: "house",
          label: "Home",
          path: homePath,
          current: window.location.pathname === homePath
        });
      }

      menuModel.forEach((menuItem: MenuItemModel) => {
        if (type === SideNavType.Home) {
          if (menuItem.label !== 'Change Management')
            sideData.push({ label: menuItem.label, labelAbbreviation: menuItem.shortLabel, isHeading: true })
        }
        let data: MenuItem = null;
        const productSubMenu = fetchChildItems(menuItem.items);
        productSubMenu.childItem.forEach((item: any) => {
          data = {
            icon: item.icon,
            label: item.label,
            current: item.current,
            ...(item.path ? { path: item.path } : { childItems: item.childNavItems }),
          };
          if (data && data.childItems?.length !== 0)
            sideData = [...sideData, data];
        });
      });
      return sideData;
    }
    else {
      return [];
    }
  }, [product, menu, permission, pathname, displayOnHomeItems, permissionsLoaded]);

  return (
    <div className={`side-nav ${sideNavState.classes}`} style={{ width: width }}>
      <Sidenav
        id={"union-sidenav"}
        data-test-id={"union-sidenav"}
        navItems={sideNav}
        onMenuItemClick={(data: any) => {
          if (data.path && data.path === "/") {
            navigate("/");
          } else {
            const route = routeMap.find((x: any) => x.path.match(data.path));
            if (route.popout) {
              lastVisitedPage(product, unionConfig, route.path);
              if (route.popout === true) {
                window.open(route.path, "_blank");
              } else {
                window.open(route.path, route.popout.target, route.popout.features);
              }
            } else {
              navigate(route.path);
            }
          }
        }}
        onNavPinned={(pinStatus: any) => {
          pin(pinStatus);
        }}
        pinned={isSideNavPinned}
      />
    </div>
  );
}

function filterMenuItemByPermissions(menuItem: PermissionedResource, permission: PermissionSet, d: { path: any; childNavItems: string | any[]; }, childData: any[]) {
  let hasPermission = false;

  if (menuItem.permissions && menuItem.permissions.length > 0) {
    hasPermission = getPermission(menuItem, permission);
  }

  if (!menuItem.permissions || menuItem.permissions.length === 0 || hasPermission) {
    if (d.path !== undefined || (d.path === undefined && d.childNavItems?.length)) {
      childData.push(d);
    }
  }
  return childData;
}
