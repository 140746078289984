import axios from "axios";
import CryptoES from "crypto-es";
import { Product, UnionConfig } from "models";

export function interceptor(unionConfig: UnionConfig) {
  axios.interceptors.request.use(function (config: any) {
    const SamAccessToken = CryptoES.AES.decrypt(sessionStorage["union-access-token"], "union-access-token")
      .toString(CryptoES.enc.Utf8)
      .replace(/^"(.*)"$/, "$1");

    config.headers.Authorization = SamAccessToken ? `Bearer ${SamAccessToken}` : "";

    config.headers["Content-Type"] = "application/json";

    if (Array.isArray(unionConfig.manifest) && unionConfig.manifest.length) {
      const productWithHeaders = unionConfig.manifest.find(
        (x: Product) =>
          x.environment?.customHeaders &&
          (config.url.startsWith(x.environment.appRootUrl) || config.url.startsWith(x.environment.apiRootUrl))
      );

      if (productWithHeaders) {
        const customHeader = productWithHeaders.environment.customHeaders;
        const modifiedConfig = {
          ...config,
          headers: {
            ...config.headers,
            ...customHeader,
          },
        };
        config = modifiedConfig;
      }
    }

    return config;
  });
}
