import { createSlice } from "@reduxjs/toolkit";
import { Menu } from "models";

interface MenuState {
  menu: Menu,
}

const defaultState: MenuState = {
  menu: null,
}

export const menuSlice = createSlice({
  name: "menu",
  initialState: defaultState,
  reducers: {
    setMenu: (state, action) => {
      return {
        ...state,
        menu: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMenu } = menuSlice.actions;

export default menuSlice.reducer;
