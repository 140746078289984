import { getAuthSettings } from "auth-settings";
import axios from "axios";
import CryptoES from "crypto-es";
import { User as OidcUser, UserManager } from "oidc-client-ts";
import { setUserAuth } from "redux/features/auth";
import { PathHelper } from "services/path-helper";
import { SessionStorageService } from "services/storage-service";
import store from "store";

export function getUser(): OidcUser {
  const userInfo: OidcUser = SessionStorageService.getItem("user-info", "user-info");
  return userInfo;
}

export function updateLastActiveProduct() {
  let activeProduct = JSON.parse(localStorage.getItem("activeProduct"));
  let lastActiveProduct = JSON.parse(localStorage.getItem("lastActiveProduct"));
  activeProduct = activeProduct === null ? [] : activeProduct;
  lastActiveProduct = lastActiveProduct === null ? [] : lastActiveProduct;
  activeProduct.filter((data: any) => {
    if (lastActiveProduct?.includes(data)) {
      return;
    } else lastActiveProduct.push(data);
  });
  localStorage.setItem("lastActiveProduct", JSON.stringify(lastActiveProduct));
  localStorage.removeItem("activeProduct");
}

export function loadUser() {
  const oidcUser: OidcUser = getUser();
  HandleUpdate(oidcUser);
  setInterval(() => updateUser(), 1200000);
}

export function updateUser() {
  getAuthSettings().then((settings) => {
    const userManager = new UserManager(settings);
    const unionConfig = store.getState().unionConfig.unionConfig;
    userManager
      .signinSilent()
      .then(async (oidcUser) => {
        const previousAccessToken: string = SessionStorageService.getItem("union-access-token", "union-access-token");
        await axios.get(unionConfig.apiUrl + "/revoke");
        SessionStorageService.setItem("user-info", JSON.stringify(oidcUser), "user-info");
        SessionStorageService.setItem(
          "union-access-token",
          JSON.stringify(oidcUser.access_token),
          "union-access-token"
        );
        Object.keys(sessionStorage)
          .filter((x) => x.startsWith("oidc."))
          .forEach((x) => sessionStorage.removeItem(x));
        HandleUpdate(oidcUser);
        sendAccessTokenToProduct(previousAccessToken);
      })
      .catch((error) => {
        console.error(error);
      });
  });
}

function HandleUpdate(oidcUser: OidcUser) {
  const _authToken = {
    value: oidcUser.access_token,
    type: oidcUser.token_type,
    expires: oidcUser.expires_at,
  };
  const oidUser = JSON.stringify(oidcUser);
  const oidUserToken = JSON.stringify(oidcUser.access_token);

  store.dispatch(
    setUserAuth({
      accessToken: CryptoES.AES.encrypt(oidUserToken, "union-access-token").toString(),
      "user-info": CryptoES.AES.encrypt(oidUser, "user-info").toString(),
      authToken: _authToken,
    })
  );
}

async function sendAccessTokenToProduct(previousAccessToken: string) {
  const manifest = store.getState().unionConfig.unionConfig.manifest;

  await Promise.allSettled(
    manifest.map((product) => {
      if (product.environment.apiEndpoints.updateToken) {
        const url = PathHelper.isAbsolutePath(product.environment.apiEndpoints.updateToken)
          ? product.environment.apiEndpoints.updateToken
          : (product.environment.apiRootUrl || product.environment.appRootUrl) +
            "/" +
            product.environment.apiEndpoints.updateToken;
        return axios.post(url, {
          previousAccessToken: previousAccessToken,
        });
      }
    })
  );
}
