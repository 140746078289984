import { Modal, ModalProps } from "@medm-ui/modal";
import "./modal.css";

export function UnionModal(props: ModalProps) {
  if (!props.actionsLayout) {
    props.actionsLayout = "end";
  }

  return (<Modal {...props} />);
}
