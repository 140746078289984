import { UnionModal } from "components/shared/modal";
import Region, { RegionProps } from "./region";
import { useAppDispatch } from "redux/hooks";
import { setUserRegion } from "redux/features/auth";
import { useLocation, useNavigate } from "react-router-dom";

const RegionSelection = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const clickHandler = (args: { id: string, title: string }) => {
        dispatch(setUserRegion(args.title));
        navigate("/");
    }

    const regions: RegionProps[] = [
        {
            id: "1",
            name: "Europe",
            abbreviation: "EU",
            onCardClick: clickHandler

        },
        {
            id: "2",
            name: "United States",
            abbreviation: "US",
            onCardClick: clickHandler
        }
    ];

    return (
        <UnionModal
            id="region-selector-modal"
            title="Select a region to login"
            size="small"
            displayCloseIcon={false}
            actionsLayout={"start"}
        >
            <div className="region-selector-modal-content">
                {regions.map(region => <Region {...region} />)}
            </div>
        </UnionModal>
    )
}

export default RegionSelection;