import { UnionModal } from "components/shared/modal";
import { TenantSwitchModalProps } from "./tenant-switcher.types";

const TenantSwitchModal = ({ item, onClose }: TenantSwitchModalProps) => {
    const onCancelButtonClick = onClose;
    const onProceedButtonClick = () => {
        const selectTenant = { 'tenantId': item.tenantId, 'tenantUniqueName': item.tenantUniqueName, 'displayName': item.displayName };
        sessionStorage.setItem('selectedTenant', JSON.stringify(selectTenant));
        if (window.eventHub) {
            const selectedTenant = sessionStorage["selectedTenant"];
            window.eventHub.emit("selectedTenant", selectedTenant);
        }
        onCancelButtonClick();
    }

    return (<UnionModal
        id="switch-tenant-modal"
        title="Switch to another tenant"
        actionsLayout={"end"}
        onClose={onCancelButtonClick}
        actions={[
            {
                buttonId: "cancelButton",
                buttonLabel: "Cancel",
                buttonType: "Subtle",
                buttonSize: "medium",
                disabled: false,
                onButtonClick: onCancelButtonClick
            },
            {
                buttonId: "proceedButton",
                buttonLabel: "Proceed",
                buttonType: "Primary",
                buttonSize: "medium",
                disabled: false,
                onButtonClick: onProceedButtonClick
            }
        ]}
    >
        Are you sure to switch to {item.displayName}?
    </UnionModal>);
}

export default TenantSwitchModal;