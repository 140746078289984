export enum FavoriteItemType { Page, Folder, UnsavedItem }

export enum FavoriteComponentState { Default, AddNewFolder, AddNewItem, RenameFolder }

export type FavoritesProps = {
    close: () => void;
    setKeepFavoritesOpen: (value: boolean) => void;
}
export interface InputState {
    inputState: "error" | "success";
    inputMessage: string;
}

export interface PageContext {
    id: string;
    title: string;
    application: string;
}

export interface FavoriteItem {
    id?: string;
    folderId?: string;
    title: string;
    type: FavoriteItemType;
    items?: FavoriteItem[];
    isOpen?: boolean;
    productKey?: string;
    hidden?: boolean;
    order?: number;
    url?: string;
    hasAccess?: boolean;
}

export interface FavoriteItemExtras {
    name: string;
    icon: string;
}

export interface CreateFavoriteItemRequest {
    title: string;
    productKey?: string;
    type: FavoriteItemType;
    folderId?: string;
    url?: string;
}

export interface CreateFavoriteItemResponse {
    id: string;
    title: string;
    type: FavoriteItemType;
    index: number
}

export type FavoritesContentProps = {
    items: FavoriteItem[];
    isFolderOpen: boolean;
    onFavoriteItemClick: (item: FavoriteItem) => void;
    onSaveItemClick: (name: string) => void;
    onUndoItemClick: () => void;
    onItemDeleteClick: (item: FavoriteItem) => void;
    onItemRenameClick: (item: FavoriteItem) => void;
    onItemMoveClick: (item: FavoriteItem) => void;
    nameInputState: InputState;
}

export type FavoriteFolderProps = {
    item: FavoriteItem;
}

export type FavoritePageProps = {
    item: FavoriteItem;
    isFolderOpen: boolean;
    onFavoriteItemClick: (item: FavoriteItem) => void;
    onSaveItemClick: (name: string) => void;
    onUndoItemClick: () => void;
    onItemDeleteClick: (item: FavoriteItem) => void;
    onItemRenameClick: (item: FavoriteItem) => void;
    onItemMoveClick: (item: FavoriteItem) => void;
    nameInputState: InputState;
    itemExtras: FavoriteItemExtras;
}

export type FavoriteActionsProps = {
    item: FavoriteItem;
    anchor: React.RefObject<HTMLDivElement>;
    showMoreIcon: boolean;
    showMoreOpen: boolean;
    setShowMoreOpen: (value: boolean) => void;
    actionItems?: FavoriteActionItem[];
}

export interface FavoriteActionItem {
    title: string;
    icon: string;
    iconLabel: string;
    clickHandler: (item: FavoriteItem) => void;
}

export type DeleteFavoriteModalProps = {
    onDeleteButtonClick: () => void;
    onCancelButtonClick: () => void;
    name: string;
}

export type MoveFavoriteModalProps = {
    items: FavoriteItem[];
    selectedItem: FavoriteItem;
    onCancelButtonClick: () => void;
    onMoveButtonClick: (selectedFolder: FavoriteItem) => void;
}

export type ReadonlyFolderProps = {
    name: string;
    transparentBackground?: boolean;
    selected: boolean;
}