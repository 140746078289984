import { AboutProps } from "components/about/about";
import { DefaultLoaderSkeleton } from "./defaultLoaderSkeleton";
import React from "react";

const About = React.lazy(() => import('components/about/about'));

export const AboutSkeleton = (props: AboutProps) => {
    return (
        <DefaultLoaderSkeleton>
            <About {...props} />
        </DefaultLoaderSkeleton>
    )
}