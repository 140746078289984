import { BreadcrumbItemData } from "@medm-ui/breadcrumb";
import { AppRoute, Menu, MenuItem, PermissionSet, RouteMap } from "models";
import { getPermission } from "services/permission/permission-helper";
import { PathHelper } from "../path-helper";
import { ProductKeys } from "models/product-keys";

export function getLocalPath(contentPath: string, routeMap: RouteMap[], productKey?: string): string {
  let productRouteMap = routeMap;
  let routeInfo;
  let routeRegex;
  if (productKey) {
    productRouteMap = productRouteMap.filter((x: { productKey: string }) => x.productKey === productKey);
  }
  const hashRouted = contentPath.startsWith("#/");
  const appPathWithoutExtras = hashRouted ? contentPath : PathHelper.removeQueryAndFragment(contentPath);
  if (productKey === ProductKeys.subscriptions) {
    // Use localpathRegex for subscriptions
    routeInfo = productRouteMap.find(
      (x: RouteMap) => !!x.localPathRegex && !!appPathWithoutExtras.match(x.localPathRegex)
    );
  } else {
    // Use contentPathRegex for others
    routeInfo = productRouteMap.find(
      (x: RouteMap) => !!x.contentPathRegex && !!appPathWithoutExtras.match(x.contentPathRegex)
    );
  }

  if (!routeInfo) {
    return undefined;
  }

  if (productKey === ProductKeys.subscriptions) {
    routeRegex = routeInfo.localPathRegex;
  } else {
    routeRegex = routeInfo.contentPathRegex;
  }

  const routeParams = extractRouteParameters(appPathWithoutExtras, routeRegex);  
  const pathExtras = getLocalPathExtras(hashRouted, routeInfo, contentPath, routeParams);

  return applyRouteParameters(routeInfo.localPathDefinition, routeParams) + pathExtras;
}

function getLocalPathExtras(hashRouted: boolean, routeInfo: RouteMap, contentPath: string, routeParams: any) {
  let pathExtras;
  if (hashRouted) {
    pathExtras = "";
  } else if (routeInfo.queryMap) {
    const queryParams = PathHelper.getParams(contentPath);
    for (const routeParam in routeInfo.queryMap) {
      const queryParam = routeInfo.queryMap[routeParam];
      const value = queryParams[queryParam];
      if (value !== undefined) {
        routeParams[routeParam] = value;
        delete queryParams[queryParam];
      }
    }
    pathExtras = PathHelper.buildQuery(queryParams) + PathHelper.getFragment(contentPath);
  } else {
    pathExtras = PathHelper.getQueryAndFragment(contentPath);
  }
  return pathExtras;
}

export function getContentPath(localPath: string, routeMap: any, productKey?: string): string {
  let productRouteMap = routeMap;
  if (productKey) {
    productRouteMap = productRouteMap.filter((x: { productKey: string }) => x.productKey === productKey);
  }
  const localPathWithoutExtras = PathHelper.removeQueryAndFragment(localPath);
  const routeInfo = productRouteMap.find((x: any) => !!localPathWithoutExtras.match(x.localPathRegex));
  if (!routeInfo) {
    return undefined;
  }

  const hashRouted = routeInfo.contentPathDefinition.startsWith("#/");
  const routeParams = extractRouteParameters(localPathWithoutExtras, routeInfo.localPathRegex);

  let pathExtras;
  if (hashRouted) {
    pathExtras = "";
  } else if (routeInfo.queryMap) {
    const queryParams = PathHelper.getParams(localPath);
    for (const routeParam in routeInfo.queryMap) {
      const value = routeParams[routeParam];
      if (value !== undefined) {
        const queryParam = routeInfo.queryMap[routeParam];
        queryParams[queryParam] = value;
        delete routeParams[routeParam];
      }
    }
    pathExtras = PathHelper.buildQuery(queryParams) + PathHelper.getFragment(localPath);
  } else {
    pathExtras = PathHelper.getQueryAndFragment(localPath);
  }

  return applyRouteParameters(routeInfo.contentPathDefinition, routeParams) + pathExtras;
}

export function getRouteByLocalPath<T>(localPath: string, routeMap: RouteMap[]): any {
  const localPathWithoutExtras = PathHelper.removeQueryAndFragment(localPath);
  const result = routeMap.find((x: any) => localPathWithoutExtras.match(x.localPathRegex));
  return result ? (result as unknown as AppRoute<T>) : undefined;
}

export function getBreadcrumbData(localPath: string, routeMap: any): BreadcrumbItemData[] {
  const breadCrumbData: BreadcrumbItemData[] = [];
  const localPathWithoutExtras = PathHelper.removeQueryAndFragment(localPath);
  const routeInfo = routeMap.find((x: any) => !!localPathWithoutExtras.match(x.localPathRegex));

  if (routeInfo) {
    const format = /[!@#$%^&*()+=\\[\]{};':"\\|,.<>\\/?]+/;
    const toPascalCase = (str: string) => {
      return str
        .toLowerCase()
        .split(/[\s_-]+/)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    };
    //Insert productKey as first breadcrumb Item
    breadCrumbData.push({
      key: routeInfo.productKey,
      label: routeInfo.productName,
    });
    routeInfo.localPathDefinition.split("/").forEach((element: any, index: number) => {
      if (element && !format.test(element) && index > 0)
        breadCrumbData.push({
          key: element,
          label: toPascalCase(element),
        });
    });
  }
  return breadCrumbData;
}

export function getRouteInfo(localPath: string, routeMap: RouteMap[]): RouteMap {
  const localPathWithoutExtras = PathHelper.removeQueryAndFragment(localPath);
  const routeInfo = routeMap.find((x: any) => !!localPathWithoutExtras.match(x.localPathRegex));

  if (routeInfo) {
    return routeInfo;
  }
  return null;
}

export function getPathMatchRegex(productKey: string, pathDef: string): RegExp {
  const parsed = pathDef
    //remove / if its the first char
    .replace(/^\//, "")
    //remove / if its the last char
    .replace(/\/$/, "")
    .split("/")
    .map((x, i) => {
      const exp1 = x.replace(/^:(\w+)/, `(?:[/]${i ? "+" : "*"}(?<$1>[^/?]+))`);
      const exp2 = x.replace(/^(\w+)/, `(?:[/]${i ? "+" : "*"}$1)`);
      return x.startsWith(":") ? exp1 : exp2;
    });
  if (productKey === ProductKeys.ilevel) {
    // Use for iLevel productKey
    return new RegExp(`^${parsed.join("")}(?:/[^/]+)*/?$`, "i");
  } else {
    // Use the default regex for other productKeys
    return new RegExp(`^${parsed.join("")}[/]*$`, "i");
  }
}

function extractRouteParameters(pathWithoutExtras: string, pathRegex: RegExp): any {
  const match = pathRegex.exec(pathWithoutExtras);
  return match ? match.groups || {} : {};
}

function applyRouteParameters(pathDefinition: string, parameters: any): string {
  let target = pathDefinition || "";

  const placeholders = target.match(/(:[^/]+)/g);
  if (placeholders) {
    placeholders.forEach((x) => (target = target.replace(x, parameters[x.replace(/[:?]/g, "")] || "")));
    target = target.replace(/\/\//g, "/");
  }

  return target;
}

export const hasAccess = (
  productKey: string,
  route: string,
  menu: Menu,
  permissionSet: PermissionSet,
  routeMap: RouteMap[]
): boolean => {
  if (menu) {
    const hasProductAccess = menu.find((item: MenuItem) => item.productKey === productKey);
    if (hasProductAccess) {
      const url = getCurrentApplicationUrl(route);
      const pageData = getRouteInfo(url, routeMap);

      if (pageData) {
        const hasPermission = getPermission(pageData, permissionSet);
        return hasPermission;
      }
    }
  }

  return false;
};

export const getCurrentApplicationUrl = (applicationUrl: string) =>
  window.location.hostname === "localhost"
    ? applicationUrl.replace("https://dev.finapps.ihsmarkit.com", "")
    : applicationUrl.replace(window.location.origin, "");
