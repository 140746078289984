import { DefaultLoaderSkeleton } from "components/skeletons";
import React from "react";
import { FavoritesProps } from "../favorites/favorites.types";

const Favorites = React.lazy(() => import('components/layout/header/favorites/favorites'));

export const FavoritesSkeleton = (props: FavoritesProps) => {
    return (
        <DefaultLoaderSkeleton>
            <Favorites {...props} />
        </DefaultLoaderSkeleton>
    )
}