import { DefaultLoaderSkeleton } from "./defaultLoaderSkeleton";
import React from "react";
import { FeedbackProps } from "components/feedback/feedback";

const Feedback = React.lazy(() => import('components/feedback/feedback'));

export const FeedbackSkeleton = (props: FeedbackProps) => {
    return (
        <DefaultLoaderSkeleton>
            <Feedback {...props} />
        </DefaultLoaderSkeleton>
    )
}