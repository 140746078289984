import { createSlice } from "@reduxjs/toolkit";
import { PermissionSet } from "models";

export const permissionSlice = createSlice({
  name: "permission",
  initialState: {
    permission: {} as PermissionSet,
    loaded: false
  },
  reducers: {
    setPermission: (state, action) => {
      return {
        ...state,
        permission: action.payload,
      };
    },
    addPermission: (state, action) => {
      return {
        ...state,
        permission: {
          ...state.permission,
          [action.payload.key]: action.payload.value
        }
      }
    },
    setLoaded: (state, action) => {
      return {
        ...state,
        loaded: action.payload
      }
    }
  },
});

export const { setPermission, addPermission, setLoaded } = permissionSlice.actions;

export default permissionSlice.reducer;
