import { Button } from "@medm-ui/button";
import { FAIcon } from "@medm-ui/icon";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import "./unauthorized.css";

export function UnauthorizedScreen() {
  const location = useLocation();
  const config = useAppSelector((state) => state.unionConfig.unionConfig);
  const pageData = config?.manifest.find((product: any) => product.name === location.state?.title);
  const buttonClick = () => {
    window
      .open("https://www.spglobal.com/marketintelligence/en/mi/Info/0722/risk-free-rates-rfr-calculator.html", "_blank")
      .focus();
  };

  return (
    <div className="unauthorizeElementsContainer">
      <FAIcon icon={`${location.state?.icon ? location.state.icon : pageData?.icon}`} color={"#999999"} />
      <p className="unauthorizedFirstParagraph">You don’t have access to {location.state?.title}.</p>
      <p className="unauthorizedSecondParagraph">
        To gain access to this application or to see how it can benefit <br />
        your business, use the options below.
      </p>
      <span className="unauthorizedButtonGroup">
        <Button
          buttonId="learn-more-button"
          buttonLabel="Learn more"
          buttonType="Primary"
          widthSize="123px"
          heightSize="40px"
          buttonSize="large"
          onButtonClick={buttonClick}
        />
        <Button
          buttonId="contact-sales"
          buttonLabel="Contact sales"
          buttonType="Tertiary"
          widthSize="136px"
          heightSize="40px"
          buttonSize="large"
          onButtonClick={buttonClick}
          className="contactSalesButton"
        />
      </span>
    </div>
  );
}
