import { Card } from "@medm-ui/card";
import { FAIcon } from "@medm-ui/icon";
import "./union-card.css";

export function UnionCard(props: any) {
  const unionLastVisitedPageCardContent = (
    <div className="cardCustomContent">
      <div className="cardIconContainer">
        {props.page.icon?.icon !== "" && (
          <FAIcon
            icon={props.isLastVisitedCard && props.page.icon ? props.page.icon.icon : ""}
            color={props.isLastVisitedCard && props.page.icon ? props.page.icon.color : ""}
            size={props.isLastVisitedCard && props.page.icon ? props.page.icon.size : ""}
          />
        )}
      </div>
      <div className="cardInfoContainer">
        {props.page?.url?.includes("@") && props.page?.url?.includes("rfr-calculator") ? (
          <span className="pageName">
            {props.page.pageName} : <span className="pageName1">{props.page.url.split("/")[3]}</span>
          </span>
        ) : (
          <span className="pageName">{props.page.pageName}</span>
        )}
        <span className="lastOpened">Last opened: {props.page.lastOpened}</span>
      </div>
    </div>
  );
  return (
    <>
      <Card
        id={props.id}
        title={props.title}
        subtitle={props.subtile}
        prefixIconClass={props.prefixIconClass}
        footer={props.footer}
        contentString={props.contentString}
        isClickable={props.isClickable}
        onCardClick={props.onCardClick}
        actions={props.actions}
        displaySwitch={props.displaySwitch}
        onSwitchClick={props.onSwitchClick}
        showCheckbox={props.showCheckbox}
        checkboxAction={props.checkboxAction}
        headerButtons={props.headerButtons}
        buttonOverFlowConfig={props.buttonOverFlowConfig}
        contentTemplate={props.isLastVisitedCard ? unionLastVisitedPageCardContent : props.contentTemplate}
        width={props.width}
      ></Card>
    </>
  );
}
