import { Loader } from "@medm-ui/loader";
import "./loader.css";
import ReactDOM from "react-dom";

export interface UnionLoaderProps{
  id?: string;
  subtle?: boolean
}

export function UnionLoader(props: UnionLoaderProps) {
  if (props.subtle === true) {
    return ReactDOM.createPortal(
      <div className="spinner" data-test-id="union-loader">
        <Loader id={props.id} size={"small"} shade={"light"}></Loader>
      </div>,
      document.body
    );
  } else {
    return ReactDOM.createPortal(
      <>
        <div className="backdrop"></div>
        <div className="loader" data-test-id="union-loader">
          <Loader id={props.id} size={"large"} shade={"light"}></Loader>
        </div>
      </>,
      document.body
    );
  }
}
