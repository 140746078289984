import { palette } from "@medm-ui/common";
import { FAIcon } from "@medm-ui/icon";
import "./app-switcher.css";
import { Label } from "@medm-ui/label";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { lastVisitedPage } from "services/last-visited-page/last-visited-page-helper";
import { MenuItem } from "models";

interface AppSwitcherProps {
    onEvent: () => void;
}

export function AppSwitcher(props: AppSwitcherProps) {
    const routeMap = useAppSelector((state) => state.route.routeMap);
    const unionConfig = useAppSelector((state) => state.unionConfig.unionConfig);
    const menu = useAppSelector((state) => state.menu.menu);
    const permission = useAppSelector((state) => state.permission.permission);
    const activeProduct = useAppSelector((state) => state.unionConfig.activeProduct);
    const navigate = useNavigate();
    const handleClickOutside = () => { props.onEvent() };
    const [productList, setProductList] = useState([]);
    const homeApplicationProductKeys = useAppSelector((state) => state.unionConfig.unionConfig.manifest.filter(item => item.displayOnHome === true).map(product => product.key));

    useEffect(() => {
        const productList: any[] = [];
        let menuModel = menu;
        if (menuModel) {
            if (homeApplicationProductKeys) {
                menuModel = menuModel.filter(m => !homeApplicationProductKeys.includes(m.productKey));
            }
            const sortedMenuModel = menuModel.slice().sort(sortMenuItems);

            sortedMenuModel.forEach((x: any) => {
            const product = unionConfig.manifest.find((productInfo) => productInfo.key === x.productKey);
            if (!product || !Object.prototype.hasOwnProperty.call(product.environments, unionConfig.defaultEnvironment)) return;

            const productPermission = permission[product.key];
            if (productPermission !== null && !(product.key === "unionadmin" && productPermission?.length === 0)) {
                productList.push({
                key: product.key,
                name: product.name,
                description: product.description,
                icon: product.icon,
                path: "/" + product.key
                });
            }
            });
        }
        setProductList(productList);
        document.addEventListener('click', handleClickOutside);
        return () => {
            // Remove event listener or unsubscribe here
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const sortMenuItems = (item1: MenuItem, item2: MenuItem) => {
        const label1 = item1.label.toLowerCase();
        const label2 = item2.label.toLowerCase();
      
        if (label1 < label2) {
          return -1;
        } else if (label1 > label2) {
          return 1;
        } else {
          return 0;
        }
      };

    const onNavigate = (data: any) => {
        if (data.path && data.path === "/") {
            navigate("/");
        } else {
            const route = routeMap.find((x: any) => (x.productKey === data.key));
            if (route.popout) {
                lastVisitedPage(activeProduct?.key, unionConfig, route.path);
                if (route.popout === true) {
                    window.open(route.path, "_blank");
                } else {
                    window.open(
                        route.path,
                        route.popout.target,
                        route.popout.features
                    );
                }
            } else {
                navigate(route.path);
            }
        }
    }
    return (
        <>
            <div className="popup-wrapper">
                <div className="apps">
                    <div className="popup-header">
                        <div className="home" onClick={() => onNavigate({ path: "/" })} onKeyDown={() => onNavigate({ path: "/" })}>
                            <FAIcon aria-label="User icon" icon="far fa-house-chimney" color={palette.gray["100"]} size="xl" />
                            <Label label={"Home"} size="XLarge" type="Body" weight="SemiBold" color="Black" className="pointer" ></Label>
                        </div>
                    </div>
                    <div className="app-scroll-container">
                        <div className="app-list">
                            {productList.map(product => (
                                <div className="app-item" key={product.name}>
                                    <div className={`app ${activeProduct?.key == product.key ? 'hover-effect' : ''}`} onClick={() => onNavigate(product)} onKeyDown={() => onNavigate(product)}>
                                        <FAIcon
                                            aria-label={`${product.name} icon`}
                                            icon={product.icon}
                                            color={palette.gray["100"]}
                                            size="m"
                                            style={{ height: '24px', width: '24px' }}
                                        />
                                        <div className="product-info">
                                            <Label
                                                label={product.name}
                                                size="Large"
                                                type="Body"
                                                weight="Bold"
                                                color="Black"
                                                className="pointer"
                                            />
                                            <Label
                                                label={product.description}
                                                size="Small"
                                                type="Body"
                                                weight="Regular"
                                                color={palette.gray["100"]}
                                                className="pointer"
                                            />
                                        </div>
                                        {activeProduct?.key === product.key && <div className="circle"></div>}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}
