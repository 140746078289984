import { useEffect, useMemo, useState } from "react";
import { SideNav, SideNavType } from "./side-nav";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./layout.css";
import { setActiveProduct } from "redux/features/unionconfig";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import logoutChannel from "services/logout/logoutChannel";
import { getRouteByLocalPath } from "services/router-helper";
import { UnionFooter } from "./footer";
import { UnionHeader } from "./header";
import { setIsSideNavPinned } from "redux/features/sideNav";
import { AboutSkeleton, EnvironmentSkeleton, FeedbackSkeleton } from "components/skeletons";

export interface LayoutProps {
}

export function Layout(props: LayoutProps) {
  const routeMap = useAppSelector((state) => state.route.routeMap);
  const unionConfig = useAppSelector((state) => state.unionConfig.unionConfig);
  const product = useAppSelector((state) => state.unionConfig.activeProduct);
  const menu = useAppSelector((state) => state.menu.menu);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [showFeedback, setshowFeedback] = useState(false);
  const [showAbout, setshowAbout] = useState(false);
  const [isPopOutWindow, setIsPopOutWindow] = useState(false);
  const isSideNavPinned = useAppSelector((state) => state.sideNav.isSideNavPinned);
  const [isProductLoaded, setIsProductLoaded] = useState(false);
  const displayOnHomeItems = useAppSelector((state) => state.unionConfig.unionConfig.manifest.filter(item => item.displayOnHome === true).map(product => product.key));

  const modalHandler = (x: any) => {
    setShowModal(x);
  };
  const feedbackHandler = (x: any) => {
    setshowFeedback(x);
  };
  const aboutHandler = (x: any) => {
    setshowAbout(x);
  };

  useEffect(() => {
    const route = getRouteByLocalPath(pathname, routeMap);
    setIsPopOutWindow(
      window.opener && window.opener !== window && window.opener.location.origin === window.location.origin
    );
    if (route) {
      const prod = unionConfig.manifest.find((x: { key: any }) => x.key === route.productKey);
      dispatch(setActiveProduct(prod));
    } else {
      dispatch(setActiveProduct(null));
    }
    if (pathname.length === 1) {
      document.title = "Union"; 
    }     
  }, [pathname]);


  useEffect(() => {
    setIsProductLoaded(false);
    const route = getRouteByLocalPath(pathname, routeMap);
    if (route) {
      const prod = unionConfig.manifest.find((x: { key: any }) => x.key === route.productKey);
      dispatch(setActiveProduct(prod));
    } else {
      dispatch(setActiveProduct(null));
    }
    setIsProductLoaded(true);
  }, []);

  useEffect(() => {
    logoutChannel.addEventListener("message", (event) => {
      if (event.origin === window.location.origin && event.data === "logout") {
        navigate("/logout");
      }
    });
  }, []);

  const sideNavType = useMemo(() => {
    if (menu === null) {
      return SideNavType.Hidden;
    }
    const pathName = window.location.pathname;
    if (product === null) {
      if ((pathName === "/" || pathname.indexOf("lastVisited") > -1) && displayOnHomeItems.length > 0 && menu.filter((menuItem) => displayOnHomeItems.indexOf(menuItem.productKey) > -1).length > 0) {

        return SideNavType.Home;
      }
      else {
        return SideNavType.Hidden;
      }
    }
    else {
      if (product.displayOnHome === true) {
        return SideNavType.Home;
      }
      else if (product.sideNavHidden === true) {
        return SideNavType.Hidden;
      }
      return SideNavType.Product;
    }
  }, [window.location.pathname, product, menu])

  const left = useMemo(
    () => {
      if (sideNavType === SideNavType.Hidden) {
        return "0px";
      }
      else if (isSideNavPinned) {
        return "265px";
      }
      else {
        return "69px";
      }
    }, [product, displayOnHomeItems, isSideNavPinned]);

  const handlePinSideNav = (value: boolean) => {
    dispatch(setIsSideNavPinned(value));
  };

  return (
    <>
      {isProductLoaded && !isPopOutWindow && (
        <>
          <UnionHeader
            openModal={modalHandler}
            openFeedback={feedbackHandler}
            openAbout={aboutHandler} />
          <div className="wrapper">
            {menu && sideNavType !== SideNavType.Hidden && <SideNav type={sideNavType} pin={(value: boolean) => handlePinSideNav(value)} width={left} />}
            <div className="child-wrapper" style={{ left: left }}>
              <div className="container">
                <Outlet />
              </div>
              <div className="footer">
                <UnionFooter />
              </div>
            </div>
          </div>
        </>)
      }
      {
        isProductLoaded && isPopOutWindow &&
        <div className="popout-container">
          <Outlet />
        </div>
      }
      {showModal && <EnvironmentSkeleton showModalHandler={modalHandler} id="environment-modal" />}
      {showFeedback && <FeedbackSkeleton showFeedbackHandler={feedbackHandler} />}
      {showAbout && <AboutSkeleton showAboutHandler={aboutHandler} id="about-modal" />}
    </>
  );
}
