import { createSlice } from "@reduxjs/toolkit";
import { AppVersionInfo } from "models/version-data/app-version-info";

export interface VersionsState {
  ui: string,
  api: string,
  umo: string,
  apps: AppVersionInfo[]
}

const defaultState: VersionsState = {
  ui: "",
  api: "",
  umo: "",
  apps: []
};

export const versionsSlice = createSlice({
  name: "versions",
  initialState: defaultState,
  reducers: {
    setUiVersion: (state, action) => {
      return {
        ...state,
        ui: action.payload
      };
    },
    setApiVersion: (state, action) => {
      return {
        ...state,
        api: action.payload
      };
    },
    setUmoVersion: (state, action) => {
      return {
        ...state,
        umo: action.payload
      };
    },
    setAppVersion: (state, action) => {
      const apps = [...state.apps];
      const index = (apps ?? []).findIndex(item => item.productKey === action.payload.productKey);
      if (index > -1) {
        apps[index] = action.payload;
      }
      else {
        apps.push(action.payload);
      }

      return {
        ...state,
        apps: apps
      };
    },
  },
});

export const { setUiVersion, setApiVersion, setUmoVersion, setAppVersion } = versionsSlice.actions;

export default versionsSlice.reducer;
