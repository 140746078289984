import axios, { AxiosResponse } from "axios";
import { Manifest, Product, UnionConfig } from "models";
import { GetManifestExtensionResponse } from "models/manifest-extension/manifest-extension";
import { setUnionConfig } from "redux/features/unionconfig";
import { interceptor } from "services/interceptor";
import store from "store";

export const loadUnionConfig = async () =>
  await fetch(window.location.origin + "/assets/union-config/" + window.location.hostname + ".json").then(
    (response) => {
      response.json().then(async (unionConfig: UnionConfig) => {
        interceptor(unionConfig);
        loadAllManifests(unionConfig);
      });
    });


const loadManifestExtensionFromLocal = async (unionConfig: UnionConfig): Promise<AxiosResponse<Manifest>> => {
  if (!unionConfig.manifestExtensionFromLocalJson) {
    return;
  }
  try {
    const url = getManifestExtensionLocalUrl();
    return await axios.get(url);
  }
  catch (error) {
    return Promise.resolve({ data: [] } as AxiosResponse<Manifest>);
  }
}

const loadManifestExtensionFromApi = async (unionConfig: UnionConfig): Promise<Manifest> => {
  if (!unionConfig.manifestExtensionFromApi) {
    return;
  }
  try {
    const url = getManifestExtensionApiUrl(unionConfig);
    const response = await axios.get<GetManifestExtensionResponse>(url);
    return response.data.map(manifest => JSON.parse(manifest.dataJson));
  }
  catch (error) {
    console.error(error);
    return Promise.resolve([] as Manifest);
  }
}


//Loads the manifest from UMO or manifest.json (original flow)
const loadAllManifests = async (unionConfig: UnionConfig) => {
  let manifestUrl;
  if (unionConfig.manifestFromApi) {
    manifestUrl = getManifestApiUrl(unionConfig);
  } else {
    manifestUrl = getManifestLocalUrl(unionConfig);
  }

  try {
    await fetchManifest(manifestUrl, unionConfig, false);
  }
  catch (error) {
    //If UMO fails, trying to fetch the manifest from local, and use the data provided by the integrated applications (old way).
    if (unionConfig.manifestFromApi) {
      await fetchManifest(getManifestLocalUrl(unionConfig), unionConfig, true);
    }
  }
}

const getManifestApiUrl = (unionConfig: UnionConfig) => unionConfig.apiUrl + "/manifest";
const getManifestLocalUrl = (unionConfig: UnionConfig) => window.location.origin + "/assets/union-config/manifest/" + unionConfig.manifest;
const getManifestExtensionLocalUrl = () => window.location.origin + "/assets/union-config/manifest-extension/manifest-extension.json";
const getManifestExtensionApiUrl = (unionConfig: UnionConfig) => unionConfig.apiUrl + "/manifestextension";

const fetchManifest = (manifestUrl: string, unionConfig: UnionConfig, fallback: boolean) => {
  return axios.get(manifestUrl).then(
    async (value: AxiosResponse<Product[], any>) => {
      let manifest = value.data;

      if (unionConfig.sandboxEnabled && localStorage.manifest) {
        manifest = JSON.parse(localStorage.manifest);
      }

      const environments = JSON.parse(localStorage.getItem("environments") || "{}");
      const hiddenProductKeys: string[] = [];

      const localManfiestExtension = (await loadManifestExtensionFromLocal(unionConfig))?.data;
      if (localManfiestExtension && localManfiestExtension.constructor === Array) {
        manifest = manifest.concat(localManfiestExtension);
      }

      const apiManifestExtension = (await loadManifestExtensionFromApi(unionConfig));
      if (apiManifestExtension && apiManifestExtension.constructor === Array) {
        manifest = [...manifest, ...apiManifestExtension];
      }


      manifest.forEach((product: Product) => {
        try {
          if (!product.environment) {
            if (environments[product.key] && product.environments[environments[product.key]]) {
              product.environment = product.environments[environments[product.key]];
            } else if (unionConfig.defaultEnvironment && product.environments[unionConfig.defaultEnvironment]) {
              product.environment = product.environments[unionConfig.defaultEnvironment];
            }
            else {
              product.environment = product.environments[Object.keys(product.environments).reverse()[0]];
            }
          } else {
            if (environments[product.key] && product.environment.name.toLocaleLowerCase() !== environments[product.key]) {
              product.environment = product.environments[environments[product.key]];
            }
          }

          if (product.environment.isVisible === false) {
            hiddenProductKeys.push(product.key);
          }
        }
        catch (error) {
          console.error(error);
        }
      });
      console.log(manifest);

      manifest = manifest.filter(product => hiddenProductKeys.indexOf(product.key) === -1);

      if (unionConfig.sandboxEnabled && localStorage.manifest) {
        localStorage.setItem("manifest", JSON.stringify(manifest));
      }

      /*In case of fallback (when UMO fails), set the manifestFromApi to false
       to use the data provided by the integrated applications (old way).*/
      if (fallback) {
        unionConfig.manifestFromApi = false;
      }

      unionConfig.manifest = manifest;
      store.dispatch(setUnionConfig(unionConfig));
    },
    (reason: any) => { throw new Error(reason) }
  );
}