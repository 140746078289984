import { DefaultLoaderSkeleton } from "./defaultLoaderSkeleton";
import React from "react";

const AllLastVisitedPages = React.lazy(() => import('components/allLastVisitedPages/allLastVisitedPages'));

export const AllLastVisitedPagesSkeleton = () => {
    return (
        <DefaultLoaderSkeleton>
            <AllLastVisitedPages />
        </DefaultLoaderSkeleton>
    )
}