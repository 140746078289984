import { Card } from "@medm-ui/card";
import "./region-selection.css";

export interface RegionProps {
    id: string;
    abbreviation: string;
    name: string;
    onCardClick: (args: { id: string, title: string }) => void
}

const Region = (props: RegionProps) => {
    return (
        <div className="region-container">
            <Card
                id={props.id}
                title={props.name}
                prefixTemplate={
                    <div className="initials">
                        {props.abbreviation}
                    </div>
                }
                isClickable={true}
                onCardClick={props.onCardClick}
            />
        </div>
    )
}

export default Region;