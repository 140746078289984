import axios from "axios";
import { LastVisitedPage } from "models/last-visited-page/last-visited-page";
import { UnionConfig } from "models/union-config";

export async function insertLastVisitedPage(config: UnionConfig, data: LastVisitedPage) {
  const LVPEndpoint = `${config.apiUrl}/LastVisited/LastVisitedPages`;
  try {
    const response = await axios.post(LVPEndpoint, data);
    return response.data;
  } catch (error: any) {
    console.error(error.response);
  }
}

export async function getLastVisitedPages(config: UnionConfig): Promise<Array<LastVisitedPage>> {
  const LVPEndpoint = `${config.apiUrl}/LastVisited/LastVisitedPages`;
  try {
    const response = await axios.get<LastVisitedPage[]>(LVPEndpoint);
    const data = response.data;
    return data.filter(item => item.visitedURL.indexOf(item.applicationName) > -1);
  } catch (error: any) {
    console.error(error.response);
  }
}
