import { createSlice } from "@reduxjs/toolkit";
import { FavoriteComponentState, FavoriteItem } from "components/layout/header/favorites/favorites.types";

export interface FavoriteState {
  currentFavoritePage?: FavoriteItem,
  favorites: FavoriteItem[],
  state: FavoriteComponentState;
}

const defaultState: FavoriteState = {
  currentFavoritePage: undefined,
  favorites: [],
  state: FavoriteComponentState.Default
};

export const favoriteSlice = createSlice({
  name: "favorite",
  initialState: defaultState,
  reducers: {
    setCurrentFavoritePage: (state, action) => {
      return {
        ...state,
        currentFavoritePage: action.payload
      }
    },
    setComponentState: (state, action) => {
      return {
        ...state,
        state: action.payload
      }
    },
    setFavorites: (state, action) => {
      return {
        ...state,
        favorites: action.payload,
      };
    }
  },
});

// Action creators are generated for each case reducer function
export const { setFavorites, setComponentState, setCurrentFavoritePage } = favoriteSlice.actions;

export default favoriteSlice.reducer;