import { createSlice } from "@reduxjs/toolkit";
import { Product } from "models/product";
import { UnionConfig } from "models/union-config";

export const unionConfigSlice = createSlice({
  name: "unionConfig",
  initialState: {
    unionConfig: {} as UnionConfig,
    activeProduct: null as Product,
  },
  reducers: {
    setUnionConfig: (state, action) => {
      return {
        ...state,
        unionConfig: action.payload
      }
    },
    setActiveProduct: (state, action) => {
      return {
        ...state,
        activeProduct: action.payload
      }
    }    
  },
});

export const { setUnionConfig, setActiveProduct } = unionConfigSlice.actions;

export default unionConfigSlice.reducer;
