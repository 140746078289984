import { configureStore } from "@reduxjs/toolkit";
import favoriteReducer from "redux/features/favorite";
import menuReducer from "redux/features/menu";
import permissionReducer from "redux/features/permission";
import routeReducer from "redux/features/routes";
import tenantReducer from "redux/features/tenant";
import versionsReducer from "redux/features/versions";
import authReducer from "./redux/features/auth";
import sideNavReducer from "./redux/features/sideNav";
import unionConfigReducer from "./redux/features/unionconfig";

const store = configureStore({
  reducer: {
    auth: authReducer,
    unionConfig: unionConfigReducer,
    menu: menuReducer,
    permission: permissionReducer,
    route: routeReducer,
    versions: versionsReducer,
    favorite: favoriteReducer,
    tenant: tenantReducer,
    sideNav: sideNavReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
