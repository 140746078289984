import axios from "axios";
import { UnionConfig } from "models";
import { PathHelper } from "services/path-helper";
import { updateLastActiveProduct } from "services/user/user.service";
import logoutChannel from "./logoutChannel";

export async function logout(unionConfig: UnionConfig, timeout?: boolean) {
  // logout from all active tabs
  if (!timeout) logoutChannel.postMessage("logout");
  try {
    await logOutProduct(unionConfig);
    await axios.get(unionConfig.apiUrl + "/revoke");
  } catch (error) {
    console.error((error as Error).message);
  }

  sessionStorage.clear();
  const logoutRedirectUrl =
    unionConfig.auth.logout +
    "&clientId=" +
    unionConfig.auth.client_id +
    "&postLogoutRedirectUrl=" +
    window.location.origin +
    "/timeout.html";

  window.location.href = timeout ? logoutRedirectUrl : unionConfig.auth.logout;
  updateLastActiveProduct();
}

const logOutProduct = async (unionConfig: UnionConfig) => {
  await Promise.allSettled(
    unionConfig.manifest.map((product) => {
      if (product.environment.apiEndpoints.logout) {
        const url = PathHelper.isAbsolutePath(product.environment.apiEndpoints.logout)
          ? product.environment.apiEndpoints.logout
          : (product.environment.apiRootUrl || product.environment.appRootUrl) +
            "/" +
            product.environment.apiEndpoints.logout;
        const logoutMethod = product.environment?.apiEndpoints.logoutMethod?.toLowerCase();
        return logOutByMethod(logoutMethod, url);
      }
    })
  );
};

const logOutByMethod = (logoutMethod: string, url: string) => {
  switch (logoutMethod) {
    case "get":
      return axios.get(url);
    case "put":
      return axios.put(url);
    case "post":
      return axios.post(url);
    case "patch":
      return axios.patch(url);
    default:
      return axios.post(url);
  }
};
