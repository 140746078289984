import { createSlice } from "@reduxjs/toolkit";
import { RouteMap } from "models";
import { RouteObject } from "react-router-dom";

export const routeSlice = createSlice({
  name: "route",
  initialState: {
    routeMap: [] as RouteMap[],
    routes: [] as RouteObject[],
  },
  reducers: {
    setRoute: (state, action) => {
      return {
        ...state,
        routeMap: action.payload.routeMap,
        routes: action.payload.routes,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setRoute } = routeSlice.actions;

export default routeSlice.reducer;
