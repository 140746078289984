import { createSlice } from "@reduxjs/toolkit";
import { AuthToken } from "models";

export interface UserState {
  authToken: AuthToken,
  "user-info": string,
  accessToken: string,
  path: string,
  region?: string
}

const initialState: UserState = {
  authToken: {} as AuthToken,
  "user-info": sessionStorage["user-info"],
  accessToken: sessionStorage["union-access-token"],
  path: "",
}

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setUserAuth: (state, action) => {
      state["user-info"] = action.payload["user-info"];
      state.accessToken = action.payload.accessToken;
      state.authToken = action.payload.authToken;
    },
    setUserRegion: (state, action) => {
      return {
        ...state,
        region: action.payload
      }
    }
  },
});

// Action creators are generated for each case reducer function
export const { setUserAuth, setUserRegion } = authSlice.actions;

export default authSlice.reducer;
