import axios from "axios";
import { PermissionList, PermissionSet, Product, UnionConfig } from "models";
import { ProductKeys } from "models/product-keys";
import permission, { addPermission, setLoaded } from "redux/features/permission";
import { httpGet } from "services/http/http.service";
import { PathHelper } from "services/path-helper";
import { SessionStorageService } from "services/storage-service";
import store from "store";

export async function loadPermission(unionConfig: UnionConfig): Promise<PermissionSet> {
  if (!unionConfig.manifest) {
    return;
  }

  const permissions = (unionConfig.manifest.filter(product => product.environment.apiEndpoints.permissions !== undefined).map(async (product) => {
    try {
      const result = await fetchProductPermissions(product);
      store.dispatch(addPermission({ key: product.key, value: result }));
    } catch (error) {
      store.dispatch(addPermission({ key: product.key, value: null }));
    }
  }))

  Promise.all(permissions).then(() => {
    store.dispatch(setLoaded(true));
  });

}

export function parsePermissionResponse(productKey: string, result: any): PermissionList {
  if (result.items) {
    result = resultGetter(result, productKey);
  }
  return result;
}

function resultGetter(result: any, productKey: string) {
  if (result.items.length > 1 && result.items[0].permissions) {
    const settings: any = SessionStorageService.getItem("multi-tenant-selection", "multi-tenant-selection");
    if (settings && settings[productKey]) {
      const match = result.items.find((x: any) => isTenantMatch(settings[productKey], x));
      if (match) {
        result = [...match.permissions, "MULTI-TENANT"];
      } else {
        result = ["MULTI-TENANT"];
      }
    } else {
      result = ["MULTI-TENANT"];
    }
  } else if (result.items.length == 1 && result.items[0].permissions) {
    result = result.items[0].permissions;
  } else {
    result = result.items;
  }
  return result;
}

function isTenantMatch(criteria: { [x: string]: any }, candidate: { [x: string]: any }): boolean {
  for (const key in criteria) {
    if (candidate[key] !== criteria[key]) {
      return false;
    }
  }
  return true;
}

export async function isRestricted(config: UnionConfig, productKey: string): Promise<string> {
  try {
    const apiUrl = config.adminApiUrl + "/UserProductPermissions/" + productKey;
    return await axios.get(apiUrl).then((response) => {
      return response.headers.isrestricted;
    });
  } catch (error: any) {
    console.log(error);
  }
}

async function fetchProductPermissions(product: Product): Promise<PermissionList> {
  const url = PathHelper.isAbsolutePath(product.environment.apiEndpoints.permissions)
    ? product.environment.apiEndpoints.permissions
    : (product.environment.apiRootUrl || product.environment.appRootUrl) +
    "/" +
    product.environment.apiEndpoints.permissions;

  const complianceRequestTimeout = 20000
  const data = await httpGet(url, product.key === ProductKeys.compliance ? complianceRequestTimeout : undefined);
  return parsePermissionResponse(product.key, data);
}
