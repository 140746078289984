import { Button } from "@medm-ui/button";
import { Header } from "@medm-ui/header";
import { FeatureFlag } from "models/feature-flag";
import moment from "moment";
import { useEffect, useState, MouseEvent } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setComponentState, setCurrentFavoritePage, setFavorites } from "redux/features/favorite";
import { useAppSelector } from "redux/hooks";
import { GetFavorites } from "services/favorite/favorite.service";
import { GetFeatureInfo } from "services/feature-flag/feature-flag.service";
import { SessionStorageService } from "services/storage-service";
import { getUser } from "services/user/user.service";
import "./header.css";
import store from "store";
import { AppSwitcher } from "./app-switcher/app-switcher";
import { FavoriteComponentState, FavoriteItem, FavoriteItemType } from "./favorites/favorites.types";
import TenantSwitchModal from "./tenant-switcher/tenant-switch-modal";
import TenantSwitcher from "./tenant-switcher/tenant-switcher";
import { TenantItem } from "./tenant-switcher/tenant-switcher.types";
import { FavoritesSkeleton } from "./skeletons";

export interface UnionHeaderProps {
  openModal?: (value: boolean) => void;
  openFeedback?: (value: boolean) => void;
  openAbout?: (value: boolean) => void;
}

export function UnionHeader(props: UnionHeaderProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const unionConfig = useAppSelector((state) => state.unionConfig.unionConfig);
  const region = useAppSelector((state) => state.auth.region);
  const currentFavoritePage = useAppSelector<FavoriteItem | undefined>((state) => state.favorite.currentFavoritePage);
  const favorites = useAppSelector<FavoriteItem[]>((state) => state.favorite.favorites);
  const [isSandboxMode, setIsSandboxMode] = useState(false);
  const activeProduct = useAppSelector((state) => state.unionConfig.activeProduct);
  const tenant = useAppSelector((state) => state.tenant);
  const [state, setState] = useState({
    userInfo: {} as { name: string, loginTime: number }
  });
  const [showAppSwitcher, setShowAppSwitcher] = useState(false);
  const [showFavorites, setShowFavorites] = useState(false);
  const [showTenantSwitcher, setShowTenantSwitcher] = useState(false);
  const [targetTenant, setTargetTenant] = useState<TenantItem | undefined>();
  const [keepFavoritesOpen, setKeepFavoritesOpen] = useState<boolean>(false);
  let favoritesDisplayTimeout: NodeJS.Timeout;
  let tenantSwitcherDisplayTimeout: NodeJS.Timeout;

  const getItemsWithUrl = (items: FavoriteItem[], url: string): FavoriteItem[] => {
    const filterFn = (item: FavoriteItem) => (item.url && item.type === FavoriteItemType.Page && item.url.toLowerCase() === url.toLocaleLowerCase());

    let filteredItems: FavoriteItem[] = [];
    items.forEach(item => {
      filteredItems = filteredItems.concat(items.filter(filterFn));
      if (item.items) {
        filteredItems = filteredItems.concat(item.items.filter(filterFn));
      }
    });
    return filteredItems;
  }

  useEffect(() => {
    let currentPage = undefined;
    if (window && window !== null) {
      const url = window.location.pathname;
      const items = favorites ? getItemsWithUrl(favorites, url) : [];
      currentPage = items.length > 0 ? items[0] : undefined;
    }
    store.dispatch(setCurrentFavoritePage(currentPage));
  }, [window.location.pathname, favorites]);

  useEffect(() => {
    const fetch = async () => {
      await GetFavorites(unionConfig).then((r: FavoriteItem[]) => {
        store.dispatch(setFavorites(r));
      });
    }
    fetch();
  }, [unionConfig]);

  useEffect(() => {
    const response = getUser();
    const data = {
      name: response.profile.name,
      loginTime: response.profile.iat,
    };
    setState((prev) => ({ ...prev, userInfo: data }));
  }, []);

  useEffect(() => {
    localStorage.getItem("manifest") ? setIsSandboxMode(true) : setIsSandboxMode(false);
  });

  const handleLogout = () => {
    navigate("/logout");
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setShowAppSwitcher(false);
    }, 200);
  }

  const handleFavoritesMouseLeave = () => {
    if (!keepFavoritesOpen) {
      favoritesDisplayTimeout = setTimeout(() => {
        closeFavorites();
      }, 1000);
    }
  }

  const closeFavorites = async () => {
    setShowFavorites(false);
    await GetFavorites(unionConfig).then((r: FavoriteItem[]) => {
      store.dispatch(setFavorites(r));
    })
    dispatch(setComponentState(FavoriteComponentState.Default));
  }

  const handleFavoritesMouseEnter = () => {
    clearTimeout(favoritesDisplayTimeout);
  }

  const handleTenantSwitcherMouseLeave = () => {
    tenantSwitcherDisplayTimeout = setTimeout(() => {
      setShowTenantSwitcher(false);
    }, 1000);
  }

  const handleTenantSwitcherMouseEnter = () => {
    clearTimeout(tenantSwitcherDisplayTimeout);
  }

  const onTenantClick = (item: TenantItem) => {
    setShowTenantSwitcher(false);
    setTargetTenant(item);
  }

  const centerItem = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span>
        {isSandboxMode && (
          <Button
            buttonId="sandbox-header-button"
            buttonLabel="SANDBOX"
            buttonType="Secondary"
            widthSize="79px"
            heightSize="24px"
            onButtonClick={() => {
              navigate("/sandbox");
            }}
            className="sandbox-button"
            buttonSize={"small"}
          />
        )}
      </span>
    </div>
  );

  const rightIcons = () => {
    const rightIcons = [];
    if (unionConfig.sandboxEnabled) {
      rightIcons.push(
        {
          label: "Environment",
          icon: "far fa-globe",
          onIconClick: () => {
            props.openModal(true);
          },
          id: "environment-selector",
          size: "lg",
          isFontAwesome: true,
        });
    }
    if (activeProduct?.environment.supportUrl) {
      rightIcons.push(
        {
          label: "Support",
          icon: "fa-regular fa-circle-question",
          onIconClick: () => {
            window.open(activeProduct.environment.supportUrl, "_blank").focus();
          },
          id: "support-selector",
          size: "lg",
          isFontAwesome: true,
        });
    }
    return rightIcons;
  };

  const tenantSwitcherProps = () => {
    //logic to display Compliance selected company
    if (activeProduct && activeProduct.key === "compliance" && sessionStorage.getItem('multi-tenant-selection')) {
      const currentMultiTenantSelection: any = SessionStorageService.getItem("multi-tenant-selection", "multi-tenant-selection")
      return ({
        currentTenantName: currentMultiTenantSelection[activeProduct.key]?.['companyName'],
      })
    } else if (activeProduct && activeProduct.tenantProvider && sessionStorage["selectedTenant"]) {
      const selectedTenant: TenantItem = JSON.parse(sessionStorage["selectedTenant"]);
      return ({
        currentTenantName: selectedTenant.displayName,
        multiTenantUser: (tenant.items.length > 1),
        onTenantSwitcherClick: () => setShowTenantSwitcher((prev) => !prev),
        tenantId: selectedTenant.tenantId
      })
    } else {
      return null
    }
  };

  return (
    <>
      <Header
        id="union-header"
        productLogo={
          <>
            {activeProduct?.name && <h2 className="product-name">{activeProduct?.name}</h2>}
          </>
        }
        appSwitcherEnabled={true}
        onAppSwitcherClick={
          (event: MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.stopPropagation();
            setShowAppSwitcher(true);
          }
        }
        onLogoClick={() => {
          navigate("/");
        }}
        rightIcons={rightIcons()}
        userInfo={{
          name: state.userInfo.name,
          lastLoginTime: moment(state.userInfo.loginTime * 1000).format(
            "DD/MM/YYYY HH:mm:ss"
          ),
        }}
        userProfileMenuItems={[
          {
            label: "Feedback",
            icon: "far fa-files",
            onMenuClick: () => {
              props.openFeedback(true);
            },
            id: "user-profile-feedback",
            isFontAwesome: true,
          },
          {
            label: "About",
            icon: "far fa-files",
            onMenuClick: () => {
              props.openAbout(true);
            },
            id: "user-profile-about",
            isFontAwesome: true,
          },
        ]}
        logOutButtonLabel={"Logout"}
        onLogoutClick={handleLogout}
        userInfoPopupId={"user-info-popup"}
        centerItem={centerItem}
        favoritesEnabled={true}
        isCurrentPageFavorite={currentFavoritePage !== undefined}
        onFavoritesClick={() => setShowFavorites(prev => !prev)}
        tenantSwitcherProps={tenantSwitcherProps()}
        regionSelectorProps={region && {
          title: `Region: ${region}`,
          id: '1'
        }}
      />
      {region}
      <div onMouseLeave={handleMouseLeave}>
        {showAppSwitcher && <AppSwitcher onEvent={handleMouseLeave} />}
      </div>
      <div onMouseLeave={handleFavoritesMouseLeave} onMouseEnter={handleFavoritesMouseEnter}>
        {showFavorites && <FavoritesSkeleton close={closeFavorites} setKeepFavoritesOpen={setKeepFavoritesOpen} />}
      </div>
      <div onMouseLeave={handleTenantSwitcherMouseLeave} onMouseEnter={handleTenantSwitcherMouseEnter}>
        {showTenantSwitcher && <TenantSwitcher onTenantClick={(item) => onTenantClick(item)} selectedTenant={JSON.parse(sessionStorage["selectedTenant"]) as TenantItem} />}
      </div>
      {targetTenant !== undefined && <TenantSwitchModal item={targetTenant} onClose={() => setTargetTenant(undefined)} />}
    </>
  );
}
