import { AppViewerProps } from "components/app-viewer/app-viewer";
import React from "react";
import { DefaultLoaderSkeleton } from "./defaultLoaderSkeleton";

const AppViewer = React.lazy(() => import('components/app-viewer/app-viewer'));

export const AppViewerSkeleton = (props: AppViewerProps) => {
    return (
        <DefaultLoaderSkeleton>
            <AppViewer {...props} />
        </DefaultLoaderSkeleton>
    )
}