import { List } from "@medm-ui/list";
import { useAppSelector } from "redux/hooks";
import { TenantSwithcerProps } from "./tenant-switcher.types";
import "./tenant-switcher.css";

const TenantSwitcher = ({
    onTenantClick,
    selectedTenant
}: TenantSwithcerProps) => {
    const tenants = useAppSelector((store) => store.tenant.items);

    return (
        <div className="tenant-switcher-popup-wrapper">
            <div className="tenant-switcher-popup">
                <List
                    selectedText={selectedTenant.displayName}
                    placeHolder="Search by name..."
                    title="EDM tenants"
                    id={"tenantlist"}
                    listData={
                        tenants.map((tenant, index) => {
                            return { id: index, value: tenant.tenantId, text: tenant.displayName, disabled: tenant.tenantId === selectedTenant.tenantId }
                        })
                    }
                    searchDelay={0}
                    type="actionable"
                    onListClick={(item) => onTenantClick(tenants[item.id])}
                />
            </div>
        </div>
    );
}

export default TenantSwitcher;