export class PathHelper {
  static getBaseSite(url: string) {
    return url
      ? url
        .replace(/^((\w+):\/\/)?((\w+):?(\w+)?@)?([^\\/\\?:]+)(:?(\d+)?)(\/?[^\\?#]+)?\??([^#]+)?#?(\w*)/, "$1$6$7")
        .toLowerCase()
      : undefined;
  }

  static isAbsolutePath(path: string): boolean {
    return path.match(/^(?:http|https):/) ? true : false;
  }

  static getAbsolutePath(path: string, relativeTo: string): string {
    if (path.startsWith("#")) {
      return relativeTo.match(/^[^\\#]+/)[0] + path;
    }

    if (PathHelper.isAbsolutePath(path)) {
      return path;
    }

    if (path.startsWith("/")) {
      return PathHelper.getBaseSite(relativeTo) + path;
    }

    const stack = relativeTo.split("/");
    const parts = path.split("/");
    stack.pop();

    parts.forEach((part) => {
      if (part === "..") {
        stack.pop();
      } else {
        stack.push(part);
      }
    });

    return stack.join("/");
  }

  static getRelativePath(path: string, relativeTo: string): string {
    const match = path.match(new RegExp(`^(?:${relativeTo})(?:[\\/])?(.*)$`, "i"));
    return match ? match[1] : path;
  }

  static removeQueryAndFragment(path: string): string {
    const match = path.match(/^([^?#]+)?(?:[?#].*)?$/);
    return match ? match[1] || "" : path;
  }

  static removeFragment(path: string): string {
    const match = path.match(/^([^\\#]+)?(?:[\\#].*)?$/);
    return match ? match[1] || "" : path;
  }

  static getQueryAndFragment(path: string): string {
    const match = path.match(/([\\?\\#].+)$/);
    return match ? match[1] : "";
  }

  static getQuery(path: string): string {
    const match = path.match(/([\\?][^\\#]+)/);
    return match ? match[1] : "";
  }

  static getFragment(path: string): string {
    const match = path.match(/([\\#].+)$/);
    return match ? match[1] : "";
  }

  static getParams(path: string): any {
    const queryString = PathHelper.getQuery(path);
    return queryString
      ? queryString
        .substring(1)
        .split("&")
        .map(
          function (this: any, n: any) {
            n = n.split("=");
            this[n[0]] = n[1];
            return this;
          }.bind({})
        )[0]
      : {};
  }

  static buildQuery(params: any): string {
    return params && Object.keys(params).length
      ? "?" +
      Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&")
      : "";
  }
}
