import CryptoES from "crypto-es";
import { UserManager, User as OidcUser } from "oidc-client-ts";
import { getAuthSettings } from "./auth-settings";
import { setUserAuth } from "./redux/features/auth";
import { SessionStorageService } from "./services/storage-service";
import store from "./store";

export class PreloadAuthenticator {
  public get authenticationRequired() {
    //Storing the intial route to redirect the user to same route after signin callback
    if (!window.location.href.includes("logout")) {
      sessionStorage["path"] = window.location.href;
    }
    const key = Object.keys(sessionStorage).find((x) => x.startsWith("union-access-token"));
    if (!key) {
      return true;
    }
    const userInfo: OidcUser = SessionStorageService.getItem("user-info", "user-info");
    if (!userInfo || new Date(userInfo.expires_at * 1000) <= new Date()) {
      sessionStorage.removeItem(key);
      return true;
    }
    return false;
  }

  public get callbackPending() {
    return !!window.location.href.match(/[?&]code=/);
  }

  public get callbackError() {
    return !!window.location.href.match(/[?&]error_description=/);
  }

  public authenticateUser() {
    this.cleanupStorage();
    getAuthSettings().then((settings) => {
      const userManager = new UserManager(settings);
      userManager
        .signinRedirect({
          redirect_uri: window.location.origin,
        })
        .catch((error) => console.error(error));
    });
  }

  public handleCallback() {
    getAuthSettings().then((settings) => {
      const userManager = new UserManager(settings);
      userManager
        .signinCallback()
        .catch((error) => console.error(error))
        .finally(() => {
          userManager.getUser().then(function (oidcUser: any) {
            const oidUser = JSON.stringify(oidcUser);
            const oidUserToken = JSON.stringify(oidcUser.access_token);
            SessionStorageService.setItem("user-info", oidUser, "user-info");
            SessionStorageService.setItem("union-access-token", oidUserToken, "union-access-token");

            store.dispatch(
              setUserAuth({
                accessToken: CryptoES.AES.encrypt(oidUserToken, "union-access-token"),
                "user-info": CryptoES.AES.encrypt(oidUser, "user-info"),
              })
            );
          });
          this.cleanupStorage();
          window.location.href = sessionStorage["path"] ? sessionStorage["path"] : window.location.origin;
        });
    });
  }

  public handleError() {
    const errorDescription = new URLSearchParams(window.location.search).get("error_description");
    console.error(errorDescription);

    getAuthSettings().then((settings) => {
      const userManager = new UserManager(settings);
      userManager.clearStaleState().then(() => (window.location.href = window.location.origin));
    });
  }

  private cleanupStorage() {
    Object.keys(localStorage)
      .filter((x) => x.startsWith("oidc."))
      .forEach((x) => localStorage.removeItem(x));
  }
}
