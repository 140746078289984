import { UnionLoader } from "components/shared/loader"
import { Suspense } from "react"

export interface DefaultLoaderSkeletonProps {
    children: React.ReactNode
}

export const DefaultLoaderSkeleton = (props: DefaultLoaderSkeletonProps) => {
    return (
        <Suspense fallback={<UnionLoader />}>
            {props.children}
        </Suspense>
    )
}