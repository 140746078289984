import axios from "axios";
import { CreateFavoriteItemRequest, CreateFavoriteItemResponse, FavoriteItem } from "components/layout/header/favorites/favorites.types";
import { UnionConfig } from "models";

export const GetFavorites = async (unionConfig: UnionConfig) => {
    const url = unionConfig.apiUrl + "/Favorites";

    try {
        const response = await axios.get(url);
        return response.data.favorites;
    } catch (error: any) {
        console.error(error.response);
        return [];
    }
}

export const CreateFavorite = async (unionConfig: UnionConfig, request: CreateFavoriteItemRequest): Promise<CreateFavoriteItemResponse> => {
    const url = unionConfig.apiUrl + "/Favorites";

    try {
        const response = await axios.post(url, request);
        return response.data;
    } catch (error: any) {
        return Promise.reject(error)
    }
}

export const DeleteFavorite = async (unionConfig: UnionConfig, id: string): Promise<void> => {
    const url = unionConfig.apiUrl + "/Favorites";

    try {
        const response = await axios.delete(url, { data: { id: id } });
        return response.data;
    } catch (error: any) {
        console.error(error.response);
    }
}

export const ModifyFavorite = async (unionConfig: UnionConfig, item: FavoriteItem): Promise<FavoriteItem> => {
    const url = unionConfig.apiUrl + "/Favorites";

    try {
        const response = await axios.patch(url, item);
        return response.data;
    } catch (error: any) {
        console.error(error.response);
    }
}

export const ReorderFavorites = async (unionConfig: UnionConfig, items: FavoriteItem[]): Promise<void> => {
    const url = unionConfig.apiUrl + "/Favorites/reorder";

    try {
        const response = await axios.patch(url, items);
        return response.data;
    } catch (error: any) {
        console.error(error.response);
    }
}